<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="elevation-0"></data-iterator>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card elevation="0" tile>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium">
            {{ modelObj._id ? 'Update Academic Year' : 'Add Academic Year' }}
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form ref="form">
            <v-container fluid class="pa-1">
              <v-row no-gutters class="pa-3 pb-0">
                <v-col cols="12">
                  <v-menu v-model="modelObj.fromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="$_formatDate(modelObj.from, 'YYYY-MM-DD', 'DD.MM.YYYY')" label="From" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                        outlined dense hide-details clearable @click:clear="modelObj.from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="modelObj.from" :max="modelObj.to" @input="modelObj.fromMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-2">
                  <v-menu v-model="modelObj.toMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="$_formatDate(modelObj.to, 'YYYY-MM-DD', 'DD.MM.YYYY')" label="To" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                        outlined dense hide-details clearable @click:clear="modelObj.to = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="modelObj.to" :min="modelObj.from" @input="modelObj.toMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6" class="pa-1" align-self="center">
                  <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive" class="ma-0"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateHandler()"> {{ modelObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset(); modelObj = {};" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      shiftTab: 0,
      listOfUserRoles: [],
      listOfEmployees: [],
      dialog: false,
      modelObj: {},
      payload: {
        showBackBtn: false,
        create: this.openDialog,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
   'data-iterator': () => import('@/components/DataIterator.vue'),
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'From',
        value: 'from',
        type: 'DATE'
      }, {
        text: 'To',
        value: 'to',
        type: 'DATE'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  mounted () {
    this.getListHandler()
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'academic-year').then(({ data }) => {
        this.payload = { ...this.payload, items: data }
      })
    },
    openDialog () {
      this.modelObj = {
        isactive: true
      }
      this.dialog = true
    },
    saveUpdateHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = this.modelObj._id ? `academic-year/${this.modelObj._id}` : `/academic-year`
        let method = this.modelObj._id ? 'put' : 'post'
        this.$_execute(method, url, this.modelObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.modelObj = {}
            this.getListHandler()
          })
          .finally(() => { this.dialog = false })
      } else {
        this.$root.$emit('snackbar', { color: 'error', message: 'Please check the required fields!' })
      }
    },
    editHandler (id) {
      this.$_execute('get', `academic-year/${id}`)
        .then(({ data }) => {
          this.modelObj = data
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'academic-year',
        _id: id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
